import React, { useState } from "react";
import { useParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import ItemList from "../components/ItemList";
import ItemForm from "../components/ItemForm";

const ProductPage = () => {
  const { productName = "" } = useParams();
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);

  const handleSave = (item) => {
    if (item.id) {
      setItems(items.map((i) => (i.id === item.id ? item : i)));
    } else {
      setItems([...items, { ...item, id: Date.now() }]);
    }
    setEditingItem(null);
  };

  const handleEdit = (item) => {
    setEditingItem(item);
  };

  const handleDelete = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  return (
    <>
      <h1>{productName}</h1>
      {/* <ItemList items={items} onEdit={handleEdit} onDelete={handleDelete} />
      <h2>{editingItem ? 'Edit Item' : 'Add New Item'}</h2>
      <ItemForm item={editingItem} onSave={handleSave} onCancel={() => setEditingItem(null)} /> */}
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {[1, 2, 3].map((value) => (
          <ListItem
            key={value}
            disableGutters
            secondaryAction={
              <IconButton aria-label="comment">
                <CommentIcon />
              </IconButton>
            }
          >
            <ListItemText primary={`Line item ${value}`} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ProductPage;
