import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  STORE_ADD_ENDPOINT_TYPE,
  STORE_GET_ENDPOINT_TYPE,
  STORE_UPDATE_ENDPOINT_TYPE,
  STORE_DELETE_ENDPOINT_TYPE,
  HTTP_METHOD,
} from "../../../constants";

const baseUrl =  process.env.REACT_APP_BASE_URL

export const getStore = createAsyncThunk(
  STORE_GET_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.GET,
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addStore = createAsyncThunk(
  STORE_ADD_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStore = createAsyncThunk(
  STORE_UPDATE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.PATCH,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStore = createAsyncThunk(
  STORE_DELETE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.DELETE,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
