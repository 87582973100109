import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    CART_ADD_ENDPOINT_TYPE,
    CART_GET_ENDPOINT_TYPE,
    CART_UPDATE_ENDPOINT_TYPE,
    CART_DELETE_ENDPOINT_TYPE,
  HTTP_METHOD,
} from "../../../constants";

const baseUrl =  process.env.REACT_APP_BASE_URL

export const getCart = createAsyncThunk(
    CART_GET_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.GET
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCart = createAsyncThunk(
    CART_ADD_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCart = createAsyncThunk(
    CART_UPDATE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.PATCH,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCart = createAsyncThunk(
    CART_DELETE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.DELETE,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
