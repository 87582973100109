import { createSlice } from "@reduxjs/toolkit";
import {
  addProduct,
  updateProduct,
  deleteProduct,
  getAllProduct,
} from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    code: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProduct.fulfilled, updateServiceState);
    builder.addCase(addProduct.fulfilled, updateServiceState);
    builder.addCase(updateProduct.fulfilled, updateServiceState);
    builder.addCase(deleteProduct.fulfilled, updateServiceState);
  },
});
