import { createSlice } from "@reduxjs/toolkit";
import { getCart, addCart, updateCart, deleteCart } from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
  },
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload)
    },
    removeItem: (state) => {
      state.value -= 1;
    },
    editItem: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, updateServiceState);
    builder.addCase(addCart.fulfilled, updateServiceState);
    builder.addCase(updateCart.fulfilled, updateServiceState);
    builder.addCase(deleteCart.fulfilled, updateServiceState);
  },
});

export const { addItem } = cartSlice.actions;
