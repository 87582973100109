import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  PRODUCT_ADD_ENDPOINT_TYPE,
  PRODUCT_GET_ENDPOINT_TYPE,
  PRODUCT_UPDATE_ENDPOINT_TYPE,
  PRODUCT_DELETE_ENDPOINT_TYPE,
  HTTP_METHOD,
} from "../../../constants";

const baseUrl =  process.env.REACT_APP_BASE_URL

export const getAllProduct = createAsyncThunk(
  PRODUCT_GET_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { token } = serviceCongfig;
      const dataEndpoint = "product/all";
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.GET,
      });
      return await response.json()
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProduct = createAsyncThunk(
  PRODUCT_ADD_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  PRODUCT_UPDATE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.PATCH,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  PRODUCT_DELETE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.DELETE,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
