import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import {
  serviceSlice,
  authSlice,
  cartSlice,
  productSlice,
  storeSlice,
  clientSlice
} from "./slice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  // storageSession,
};

const appReducer = combineReducers({
  service: serviceSlice.reducer,
  auth: authSlice.reducer,
  cart: cartSlice.reducer,
  product: productSlice.reducer,
  store: storeSlice.reducer,
  client: clientSlice.reducer
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
 };

//const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer
});

// export const persistor = persistStore(store);
