import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_DATA_ENDPOINT_TYPE,
  CREATE_DATA_ENDPOINT_TYPE,
  PATCH_DATA_ENDPOINT_TYPE,
  HTTP_METHOD,
} from "../../../constants";

export const createData = createAsyncThunk(
  CREATE_DATA_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { baseUrl, dataEndpoint, body } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getData = createAsyncThunk(
  GET_DATA_ENDPOINT_TYPE,
  async (serviceCongfig, { getState, rejectWithValue }) => {
    try {
      const { baseUrl, dataEndpoint } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {},
        method: HTTP_METHOD.GET,
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const patchData = createAsyncThunk(
  PATCH_DATA_ENDPOINT_TYPE,
  async (serviceCongfig, { getState, rejectWithValue }) => {
    try {
      const { baseUrl, dataEndpoint } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {},
        method: HTTP_METHOD.PATCH,
        body: {},
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
