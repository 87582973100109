import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useStore, useSelector } from "react-redux";
import { addItem, productSlice } from "../../redux/slice";
import Chevrolet from "../../images/Chevrolet.jpg";

export default function ProductCard(props) {
  const { products } = props;
  const reduxStore = useStore();
  const { dispatch } = reduxStore;

  const handleClick = (product) => {
    console.log(product);
    const itemDetail = {
      id: product?._id,
      name: product?.name,
      sku: product?.sku,
      locationId: product?.location?.[0]._id,
      quantity: 1,
      subTotal: product?.purchasingPrice,
      unit: product?.unit,
      tax: product?.tax,
      discount: product?.discount,
      isPriceEdited: false,
    };
    dispatch(addItem(itemDetail));
  };

  const renderProductCard = () => {
    return products.map((product) => (
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="140"
          image={Chevrolet}
          alt="Chevrolet"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Chevrolet is an iconic American car brand known for its reliable,
            dependable, and affordable vehicles. Founded in 1911, Chevy has
            become one of the most recognizable car brands in the world.
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={() => handleClick(product)}>
            Add To Cart
          </Button>
        </CardActions>
      </Card>
    ));
  };
  return <>{renderProductCard()}</>;
}
