import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear user data from localStorage or sessionStorage (whichever you're using)
    //localStorage.removeItem('userToken'); // Example: Clearing user token
    sessionStorage.clear();

    // Optionally, you can call an API to log the user out on the server side

    // Redirect to login page after a delay
    setTimeout(() => {
      navigate('/login'); // Redirect to login page or any other page
    }, 2000); // Redirect after 2 seconds
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>You have been logged out.</h2>
      <p>Redirecting to login page...</p>
    </div>
  );
};

export default LogoutPage;