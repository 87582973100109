import { createSlice } from '@reduxjs/toolkit'
import {getData, createData, patchData} from '../../actions'
import { updateServiceState } from '../../../utils/redux/redux'

export const serviceSlice = createSlice({
    name: 'service',
    initialState: {
      value: 0,
    },
    reducers: {
      increment: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes.
        // Also, no return statement is required from these functions.
        state.value += 1
      },
      decrement: (state) => {
        state.value -= 1
      },
      incrementByAmount: (state, action) => {
        state.value += action.payload
      },
    },
    extraReducers: builder => {
      builder.addCase(getData.fulfilled, updateServiceState)
      builder.addCase(createData.fulfilled, updateServiceState)
      builder.addCase(patchData.fulfilled, updateServiceState)
    }
  })