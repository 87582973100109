import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CLIENT_ADD_ENDPOINT_TYPE,
  CLIENT_GET_ENDPOINT_TYPE,
  CLIENT_UPDATE_ENDPOINT_TYPE,
  CLIENT_DELETE_ENDPOINT_TYPE,
  HTTP_METHOD,
} from "../../../constants";

const baseUrl =  process.env.REACT_APP_BASE_URL

export const getClient = createAsyncThunk(
    CLIENT_GET_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.GET,
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCLient = createAsyncThunk(
    CLIENT_ADD_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateClient = createAsyncThunk(
    CLIENT_UPDATE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.PATCH,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteClient = createAsyncThunk(
    CLIENT_DELETE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.DELETE,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
