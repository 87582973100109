import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import AddTABCModal from "../modal/AddTABCModal";

const columns = [
  { id: "id", label: "id", minWidth: 50 },
  {
    id: "receiveDate",
    label: "Receive Date",
    minWidth: 50,
    format: (value) => value.toLocaleDate("en-US"),
  },
  { id: "startNum", label: "Start Number", minWidth: 50 },
  { id: "endNum", label: "End Number", minWidth: 50, align: "right" },
  { id: "total", label: "Total Count", minWidth: 50, align: "right" },
  // {
  //   id: 'population',
  //   label: 'Population',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
];

function createData(id, receiveDate, startNum, endNum) {
  const total = endNum - startNum;
  return { id, receiveDate, startNum, endNum, total };
}
const rows = [
  createData("499152920005", "01/12/2024", 100042, 200142),
  createData("499152920005", "02/12/2024", 300042, 440042),
  createData("499152920005", "03/12/2024", 500042, 602042),
  createData("499152920005", "04/12/2024", 700042, 800242),
  createData("499152920005", "05/12/2024", 900042, 1020042),
  createData("499152920005", "06/12/2024", 1100042, 1230042),
];

const InventoryPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = () => {
    handleOpen()
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get("startNum"))
    console.log(data.get("endNum"))
    handleClose()

  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <h1 style={{ textAlign: "center" }}>TABC</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginInlineEnd: 20,
          }}
        >
          <Button onClick={(event) => handleClick(event)}>Add TABC</Button>
        </div>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {open && (
        <AddTABCModal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleSubmit= {handleSubmit}
        />
      )}
    </>
  );
};

export default InventoryPage;
