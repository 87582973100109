import * as React from "react";
import { useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AccountMenu from "../AccountMenu/AccountMenu.js";
import { selectItems, selectAccessToken } from "../../redux/selectors/index.js";

function Header(props) {
  const { title } = props;
  const navigate = useNavigate();
  const token = useSelector(selectAccessToken);
  const items = useSelector(selectItems);
  const routeChange = (path) => {
    navigate(path);
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  return (
    <React.Fragment>
      <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
        {token && <AccountMenu />}
        <Typography
          component="h2"
          variant="h5"
          color="blue"
          align="center"
          noWrap
          sx={{ flex: 1 }}
          onClick={() => routeChange("/")}
        >
          {title}
        </Typography>
        {token && (
          <div>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}

            <Button
              variant="outlined"
              size="small"
              onClick={() => routeChange("login")}
            >
              Login
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={() => routeChange("signup")}
            >
              Sign up
            </Button>

            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => routeChange("cart")}
            >
              <AddShoppingCartIcon />
              <Typography
                component="h6"
                variant="h6"
                color="inherit"
                align="center"
                noWrap
                sx={{ mb: 2 }}
              >
                {items.length > 0 ? items.length : null}
              </Typography>
            </IconButton>
          </div>
        )}
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
