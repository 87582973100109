import { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import ProductCard from "../../src/components/Card/Card";
import { useNavigate } from "react-router-dom";
import { useStore, useSelector } from "react-redux";
import { getAllProduct } from "../redux/actions";
import {
  selectAccessToken,
  selectAllProduct,
} from "../redux/selectors/index";

const sections = [
  { title: "Wine", url: "/product/wine" },
  { title: "Spirits", url: "/product/spirits" },
  { title: "Beer", url: "/product/beer" },
];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function HomePage() {
  const navigate = useNavigate();
  const reduxStore = useStore();
  const token = useSelector(selectAccessToken);
  const productList = useSelector(selectAllProduct);

  useEffect(() => {
    if (productList.length === 0) {
      console.log('token', token)
      if(token) {
        const serviceConfig = {
          token,
        };
        reduxStore.dispatch(getAllProduct(serviceConfig));
      } else {
        navigate('/login')
      }

      
    }
  }, [productList, reduxStore, token]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Toolbar
          component="nav"
          variant="dense"
          sx={{ justifyContent: "space-between", overflowX: "auto" }}
          maxWidth="lg"
        >
          {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              sx={{ p: 1, flexShrink: 0 }}
            >
              {section.title}
            </Link>
          ))}
        </Toolbar>
        <main>
          <Grid
            container
            spacing={6}
            sx={{ mt: 5, justifyContent: "space-around" }}
          >
            <ProductCard products={productList} />
          </Grid>
        </main>
      </Container>
    </ThemeProvider>
  );
}
