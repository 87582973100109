import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { addCLient } from "../redux/actions";
import { useStore, useSelector } from "react-redux";
import { selectAccessToken, selectClientError } from "../redux/selectors/index";
import { useNavigate } from "react-router-dom";
import { addClientDetail} from '../redux/slice'

const defaultTheme = createTheme();

export default function BusinessSetupPage() {
  const [isApi, setIsApi] = useState(false)
  const reduxStore = useStore();
  const {dispatch} = reduxStore
  const navigate = useNavigate();
  const token = useSelector(selectAccessToken);
  const clientError = useSelector(selectClientError);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const requestBody = {
      companyName: data.get("businessName"),
      email: data.get("email"),
      phoneNumber: data.get("phoneNumber"),
      address: `${data.get("address")}, ${data.get("city")}, ${data.get("state")}, ${data.get("zipCode")}, ${data.get("country")} `,
      registrationNumber: data.get("permit"),
      permitExpiryDate: data.get("permitExpiryDate"),
    };
    dispatch(addClientDetail(requestBody))
    const serviceConfig = {
      baseUrl: "http://localhost:8080/v1",
      dataEndpoint: "client/add-new-client",
      body: requestBody,
      token,
    };
    dispatch(addCLient(serviceConfig));
    setIsApi(true)
  };

  useEffect(() => {
    if(isApi && !clientError) {
      navigate('/signup')
    }
  }, [clientError, isApi, navigate])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Business Setup
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="businessName"
                  required
                  fullWidth
                  id="businessName"
                  label="Business Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone"
                  name="phoneNumber"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="state"
                  label="state"
                  name="state"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="zipCode"
                  label="Zip Code"
                  name="zipCode"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="permit"
                  label="Permit Number"
                  name="permit"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="permitExpiryDate"
                  label="Expiry Date"
                  name="permitExpiryDate"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
