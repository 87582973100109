import React from 'react';

const Item = ({ item, onEdit, onDelete }) => {
  return (
    <div className="item">
      <h3>{item.name}</h3>
      <p>Quantity: {item.quantity}</p>
      <button onClick={() => onEdit(item)}>Edit</button>
      <button onClick={() => onDelete(item.id)}>Delete</button>
    </div>
  );
};

export default Item;
