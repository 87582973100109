import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import { selectItems } from "../redux/selectors/cart/cart";
import { useSelector } from "react-redux";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

const columns = [
  { id: "sku", label: "SKU", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 50 },
  { id: "unit", label: "Size", minWidth: 50, align: "right" },
  { id: "quantity", label: "Quantity", minWidth: 50, align: "right" },
  { id: "discount", label: "Discount", minWidth: 50, align: "right" },
  { id: "subTotal", label: "Price", minWidth: 50, align: "right" },
];

const CartPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPrice, setTotalPrice] = useState(0);
  const rows = useSelector(selectItems);
  const [cartItems, setCartItems] = useState(rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleQuantityChange = (id, newQuantity) => {
    setCartItems(
      cartItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const handleDelete = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  const handleClick = (event, id) => {
    console.log(event, id);
  };
  useEffect(() => {
    if (cartItems.length > 0) {
      let price = 0;
      cartItems.map((items) => {
        price = price + items.subTotal;
      });
      setTotalPrice(price);
    }
  }, [cartItems]);

  const handlePlaceOrder = () => {
    const requestBody = {
      product: [
        {
          id: '',
          locationId: '',
          quantity: 0,
          subTotal: 0,
          unit: '',
          tax: 0,
          discount: 0,
          isPriceEdited: false,
        },
      ],
      discount: 0,
      // tax: number;
      invoiceId: '',
      customerId: '',
      totalPrice: '',
      paymentMethod: '',
      paymentStatus: '',
    };
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <h1 style={{ textAlign: "center" }}>Cart Details</h1>
      <TableContainer sx={{ maxHeight: 440,}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Button onClick={(event) => handleClick(event, row)}>
                        Edit
                      </Button>
                      <Button onClick={(event) => handleDelete()}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cartItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Typography component="h1" variant="h5">
        Total: {totalPrice}
      </Typography>
    </Paper>
  );
};

export default CartPage;

const initialCart = [
  { id: 1, name: "Product A", price: 50, quantity: 2 },
  { id: 2, name: "Product B", price: 30, quantity: 1 },
  { id: 3, name: "Product C", price: 70, quantity: 3 },
];

// const CartPage = () => {
//   const [cartItems, setCartItems] = useState(initialCart);

//   const handleQuantityChange = (id, newQuantity) => {
//     setCartItems(
//       cartItems.map(item =>
//         item.id === id ? { ...item, quantity: newQuantity } : item
//       )
//     );
//   };

//   const handleDelete = id => {
//     setCartItems(cartItems.filter(item => item.id !== id));
//   };

//   const calculateTotalPrice = () => {
//     return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
//   };

//   return (
//     <div className="cart-container">
//       <h2>Shopping Cart</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Product</th>
//             <th>Price</th>
//             <th>Quantity</th>
//             <th>Total</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {cartItems.map(item => (
//             <tr key={item.id}>
//               <td>{item.name}</td>
//               <td>${item.price}</td>
//               <td>
//                 <input
//                   type="number"
//                   min="1"
//                   value={item.quantity}
//                   onChange={(e) =>
//                     handleQuantityChange(item.id, parseInt(e.target.value))
//                   }
//                 />
//               </td>
//               <td>${item.price * item.quantity}</td>
//               <td>
//                 <button onClick={() => handleDelete(item.id)}>Delete</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <h3>Total Price: ${calculateTotalPrice()}</h3>
//     </div>
//   );
// }

// export default CartPage;
