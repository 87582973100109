import { createSlice } from "@reduxjs/toolkit";
import { addStore, updateStore, deleteStore, getStore } from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

export const storeSlice = createSlice({
  name: "store",
  initialState: {
    data: [],
    code: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStore.fulfilled, updateServiceState);
    builder.addCase(addStore.fulfilled, updateServiceState);
    builder.addCase(updateStore.fulfilled, updateServiceState);
    builder.addCase(deleteStore.fulfilled, updateServiceState);
  },
});
