import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import InventoryPage from './pages/InventoryPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ProductPage from './pages/ProductPage';
import TABCPage from './pages/TABCPage';
import CartPage from './pages/CartPage'
import OrderPage  from './pages/OrderPage';
import StoreSetupPage from './pages/StoreSetupPage'
import ClientSetupPage from './pages/ClientSetupPage'
import LogoutPage from './pages/LogoutPage'


import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';


const App = () => {
  return (
    <Router>
      <Header title="Esters Liquor" />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/inventory" element={<InventoryPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/product/:productName" element={<ProductPage />} />
        <Route path="/tabc" element={<TABCPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/storeSetup" element={<StoreSetupPage />} />
        <Route path="/clientSetup" element={<ClientSetupPage />} />
        <Route path="/logout" element={<LogoutPage />} />
      </Routes>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </Router>
  );
};

export default App;
