import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chevrolet from "../images/Chevrolet.jpg";
// import { useStore, useSelector } from "react-redux";
// import { addItem } from "../../redux/slice";

export default function OrderPage(props) {
  // const { products } = props;
  // const reduxStore = useStore();
  // const { dispatch } = reduxStore;

  // const handleClick = (product) => {
  //   console.log(product)
  //   dispatch(addItem(product))
    
  // }
  return (
    <div style={{ margin: "25%" }}>
      {/* {products.map((product) => {
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="140"
            image={Chevrolet}
            alt="Chevrolet"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            {product?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Chevrolet is an iconic American car brand known for its reliable,
              dependable, and affordable vehicles. Founded in 1911, Chevy has
              become one of the most recognizable car brands in the world.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => handleClick(product)}>Add To Cart</Button>
          </CardActions>
        </Card>;
      })} */}
    </div>
  );
}
