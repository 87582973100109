export const GET_DATA_ENDPOINT_TYPE = 'dataEndpoint/get'
export const CREATE_DATA_ENDPOINT_TYPE = 'dataEndpoint/create'
export const PATCH_DATA_ENDPOINT_TYPE = 'dataEndpoint/patch'

export const AUTH_LOGN_ENDPOINT_TYPE = 'auth/login'
export const AUTH_SIGNUP_ENDPOINT_TYPE = 'auth/signup'

export const PRODUCT_ADD_ENDPOINT_TYPE = 'product/add'
export const PRODUCT_GET_ENDPOINT_TYPE = 'product/get'
export const PRODUCT_UPDATE_ENDPOINT_TYPE = 'product/update'
export const PRODUCT_DELETE_ENDPOINT_TYPE = 'product/delete'

export const CART_ADD_ENDPOINT_TYPE = 'cart/add'
export const CART_GET_ENDPOINT_TYPE = 'cart/get'
export const CART_UPDATE_ENDPOINT_TYPE = 'cart/update'
export const CART_DELETE_ENDPOINT_TYPE = 'cart/delete'

export const TABC_STAMP_ADD_ENDPOINT_TYPE = 'tabc/stamp/add'
export const TABC_STAMP_GET_ENDPOINT_TYPE = 'tabc/stamp/get'
export const TABC_STAMP_UPDATE_ENDPOINT_TYPE = 'tabc/stamp/update'
export const TABC_STAMP_DELETE_ENDPOINT_TYPE = 'tabc/stamp/delete'

export const STORE_ADD_ENDPOINT_TYPE = 'store/add'
export const STORE_GET_ENDPOINT_TYPE = 'store/get'
export const STORE_UPDATE_ENDPOINT_TYPE = 'store/update'
export const STORE_DELETE_ENDPOINT_TYPE = 'store/delete'

export const CLIENT_ADD_ENDPOINT_TYPE = 'client/add'
export const CLIENT_GET_ENDPOINT_TYPE = 'client/get'
export const CLIENT_UPDATE_ENDPOINT_TYPE = 'client/update'
export const CLIENT_DELETE_ENDPOINT_TYPE = 'client/delete'