import { createSlice } from "@reduxjs/toolkit";
import { login } from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

const initialState = {
  access: {
    token: null,
    expires: null,
  },
  refresh: {
    token: null,
    expires: null,
  },
  code: null,
  message: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userlogin: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, updateServiceState);
  },
});

export const { userlogin, logout } = authSlice.actions;
