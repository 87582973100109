import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';

const columns = [
  { id: 'upc', label: 'UPC', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 50 },
  { id: 'department', label: 'Department', minWidth: 50 },
  { id: 'qty', label: 'Quantity', minWidth: 50, align: 'right', },
  { id: 'size', label: 'Size', minWidth: 50, align: 'right', },
  { id: 'costPrice', label: 'Cost Price', minWidth: 50 , align: 'right',},
  { id: 'sellPrice', label: 'Sell Price', minWidth: 50, align: 'right', },
  // {
  //   id: 'population',
  //   label: 'Population',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // },

];


function createData(upc, name, department, qty, size, costPrice, sellPrice) {
  // const density = population / size;
  return { upc, name, department, qty, size, costPrice, sellPrice };
}
const rows= [
  createData( '499152920005', 'Jagermister', 'SGWS: CORDIALS', '1', '50ML', '0.39', '0.99'),
  createData( '499156900003', 'BransonRoyal', 'SGWS: BRANDY & COGNAC', '1', '750ML', '56.49', '56.49'),
  createData( '499156900072', 'Deleon Tequilla', 'SGWS: TEQUILA', '1', '750ML', '38.99', '38.99'),
  createData( '499152920005', 'Jagermister', 'SGWS: CORDIALS', '1', '50ML', '0.39', '0.99'),
  createData( '499152920005', 'Jagermister', 'SGWS: CORDIALS', '1', '50ML', '0.39', '0.99'),
  createData( '499152920005', 'Jagermister', 'SGWS: CORDIALS', '1', '50ML', '0.39', '0.99'),
  createData( '499152920005', 'Jagermister', 'SGWS: CORDIALS', '1', '50ML', '0.39', '0.99'),
]

const InventoryPage = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, id) => {
    console.log(event, id)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <h1 style={{textAlign:'center'}}>Inventory</h1>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell> 
                          <Button onClick={(event) => handleClick(event, row)}>Edit</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default InventoryPage;

